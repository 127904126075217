<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="#0b86b3"
          hover-reveal
          type="Bar"
        >
          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">Monthly Sales</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="#0b86b3"
          hover-reveal
          type="Line"
        >
          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">Weekly Sales</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          hover-reveal
          color="#0b86b3"
          type="Line"
        >
          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">Daily Sales</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="#076128"
          icon="mdi-poll"
          title="QUOTATIONS"
          :value="quotationCount"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="dark"
          icon="mdi-poll"
          title="TRANSACTIONS"
          :value="transactionCount"
          sub-icon="mdi-tag"
          sub-text="Tracked from all Agents"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="#64078c"
          icon="mdi-store"
          title="SALES"
          :value="`$${totalSales.toFixed(2)}`"
          sub-icon="mdi-calendar"
          sub-text="Sales Per Month"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="#085d82"
          icon="mdi-sofa"
          title="USERS"
          value="2"
          sub-icon="mdi-alert"
          sub-icon-color="purple"
          sub-text="Get More Space...More Sales"
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
          color="dark"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Agent Stats
            </div>

            <div class="subtitle-1 font-weight-light">
              All Agents' Monthly Statistics, 2024
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { Vue } from "vue";
  export default {
    name: 'Dashboard',

    data () {
      return {
        totalSales: 0,
        transactionCount:0,
        quotationCount: 0,
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [

          {
            sortable: false,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Sales',
            value: 'sales',
          },
          {
            sortable: false,
            text: 'Commission ZIG',
            value: 'zig',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Commission USD',
            value: 'usd',
            align: 'right',
          },
          {
            sortable: false,
            text: 'OFFICE',
            value: 'office',
            align: 'right',
          },
        ],
        items: [
          {
            sales: 425,
            name: 'Kupakwashe Dumba',
            usd: '$250',
            office: 'ECO BANK',
            zig: '$35738',
          },

        ],

      }
    },

    computed: {
      ...mapState(["is_supervisor", "access_token", "base_url"]),
    },
    beforeMount() {

    },
    created() {
    },
    methods: {
      async fetchTotalSales() {
        try {
          const response = await fetch('https://verniabackend.vernia.co.zw/transactions/'); // Adjust with your actual API endpoint
          const data = await response.json();

          // Calculate the cumulative total of `amount_paid`
          this.totalSales = data.reduce((total, transaction) => {
            return total + parseFloat(transaction.amount_paid || 0);
          }, 0);

        } catch (error) {
          console.error('Error fetching total sales:', error);
        }
      },
      async fetchQuotationCount() {
        try {
          const response = await fetch('https://verniabackend.vernia.co.zw/api/quotationsnew'); // Adjust with your actual API endpoint
          const data = await response.json();
          this.quotationCount = data.length; // Count the number of items in the array
        } catch (error) {
          console.error('Error fetching quotation count:', error);
        }
      },
      async fetchTransactionCount() {
        try {
          const response = await fetch('https://verniabackend.vernia.co.zw/transactions/'); // Adjust with your actual API endpoint
          const data = await response.json();
          this.transactionCount = data.length; // Count the number of items in the array
        } catch (error) {
          console.error('Error fetching transaction count:', error);
        }
      },

    },
    mounted() {
      this.fetchQuotationCount();
      this.fetchTransactionCount();
      this.fetchTotalSales();
    },
  }
</script>
