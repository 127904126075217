
<template>
  <div>
    <Invoice ref="printableArea" :item="currentItem" />
  <v-container
    id="Quotations"
    fluid
    tag="section"
  >
    <div class="py-3" />

    <base-material-card
      icon="mdi-clipboard-text"
      title="Quotations"
      class="px-5 py-3"
      color="#09ab2e"
    >
<!--      <v-simple-table>-->
<!--        <thead>-->
<!--        <tr>-->
<!--          &lt;!&ndash; map each header name to a table-cell (<td>) element &ndash;&gt;-->
<!--          <th v-for="head in headers" :key="head.value">{{ head.name }}</th>-->
<!--          <th>QUOTE STATUS</th>-->
<!--          <th>OPTIONS</th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        &lt;!&ndash; for each item in 'items', create a table-row with table-cells &ndash;&gt;-->
<!--        <tr v-for="(item, index) in items" :key="index">-->
<!--          &lt;!&ndash; for each header, create a table-cell (<td>) with the respective item property value &ndash;&gt;-->
<!--          <td v-for="head in headers" :key="head.value">{{ item[head.value] }}</td>-->
<!--          <td><v-btn small color="yellow">PENDING</v-btn></td>-->
<!--          <td><v-icon class="mx-1" color="info" @click="editItem(item)">mdi-pencil</v-icon>-->
<!--            <v-btn small color="primary" @click="printItem(item)">Print</v-btn>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </v-simple-table>-->
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="itemsPerPage"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Quotation List</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spaer></v-spaer>
            <v-text-field
              v-model="itemsPerPage"
              label="Rows per page"
              type="number"
              min="1"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="" value="option1">
            mdi-pencil
          </v-icon>
          <v-btn small color="primary" @click="printItem(item)" value="option2">
            Print
          </v-btn>
        </template>
      </v-data-table>

    </base-material-card>
  </v-container>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from 'html2pdf.js';
import Vue from "vue";
import moment from "moment";
import xlsx from "xlsx";
import Invoice from "@/views/dashboard/pages/invoice.vue";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
  name: "Quotations",
  components: {Invoice},
  data() {
    return {
      currentItem: null,
      headers: [
        { value: 'vrn', text: 'VRN' },
        { value: 'agent', text: 'AGENT' },
        { value: 'client_name', text: 'CLIENT NAME' },
        { value: 'client_number', text: 'CLIENT NUMBER' },
        { value: 'make_model', text: 'MAKE/MODEL' },
        { value: 'insurancePeriod', text: 'PERIOD' },
        { value: 'sum_insured', text: 'VEHICLE VALUE' },
        { value: 'rate', text: 'RATE' },
        {value : 'amount', text: 'AMOUNT'},
        { value: 'createdDate', text: 'QUOTE DATE' },
        { text: 'Options', value: 'action', sortable: false },

      ],
      items: [],
      arr1: [],
      arr2: [],
      all_data: [],
      dates: [],
      menu: false,
      modal: false,
      delivery_town_object: null,
      Address1: "",
      viewTransactionDetails: false,
      Address2: "",
      selected_gender: "",
      delivery_town: "",
      client_contactnumber: "",
      trans_status: null,
      payment_message: "Sending Payment Request...",
      radio: null,
      RadioType: null,
      overlay: false,
      LicenceID: null,
      EcocashWait: 0,
      retrieve_policyDialog: false,
      TotalTransAmount: "---",
      AdministrationAmt: "---",
      TransactionLicAmt: "---",
      ArrearsAmt: "---",
      PenaltiesAmt: "---",
      TotalLicAmt: "---",
      TotalRadioTVAmt: "---",
      RadioTVArrearsAmt: "---",
      TotalAmount: "---",
      licence_expiry: null,
      frequency: null,
      payment_methods: [
        { name: "Ecocash", value: 3 },
        { name: "Cash", value: 1 },
        { name: "Point of Sale", value: 2 },
      ],
      delivery_methods: [
        { name: "Postal", value: 1 },
        { name: "Office Collection", value: 2 },
      ],
      payment_method: null,
      delivery_method: null,
      client_idnumber: "",
      RadioTVAmt: "",
      show_quote: false,
      date: new Date().toISOString().substring(0,10),
      valid: false,
      paymentForm_valid: false,
      trans_summary: false,

      vehicle_type: null,
      vrn_type: [
        { name: "Private Car", value: 1 },
        { name: "Commercial Vehicle", value: 8 },
        { name: "Omnibus and Commuters", value: 22 },
        { name: "Ambulance and Fire Engines, Hearse", value: 35 },
        { name: "Taxi", value: 13 },
      ],
      company_type: "",
      co_type: [
        {name: "MOOVAH", value: "MOOVAH"},
        {name: "FBC Insurance", value: "FBC Insurance"},
        {name: "NICOZ", value: "NICOZ"},
      ],
      vehicle_use: null,
      vehicle_make: "null",
      vehicle_model: "null",
      yearOfmanufacture: "null",
      tax_class: null,
      vrn_use: [],
      qoutes: [],
      covers: [
        { name: "4 Months", value: 1 },
        { name: "8 Months", value: 3 },
        { name: "12 Months", value: 6 },
      ],
      radio_options: [
        { name: "Yes", value: 1 },
        { name: "No", value: 5 },
      ],
      add: false,
      vrn: null,
      pay_dialog: false,
      quote_message: null,
      quote_type: null,
      search: null,
      client_name: null,
      client_surname: null,
      client_email: null,
      client_phonenumber: "",
      paid_amount: null,
      cover_period_approved: null,
      approved_policy_message: null,
      approved_trans_status: null,
      approved_Vehiclevrn: null,
      IDNumber: null,
      LoadedBy: null,
      IssuerCompany: null,
      Message: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 10 || "Max 10 characters",
        counter_id: (value) => value.length >= 11 || "Max 11 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      links: [
        {
          text: "Licensing - Radio and License",
          disabled: false,
          href: "RadioZinara",
        },
        {
          text: "Generate Quote",
          disabled: true,
          href: "",
        },
      ],
      zone: null,
      licence_fee: null,
      licence_arrears: null,
      penalty: null,
      administration_amount: null,
      total_licence_amount: null,
      radio_tv_amount: null,
      total_radio_tv_amount: null,
      certificate_serial_no: null,
      business_source: null,
    };
  },
  watch: {
    EcocashWait: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.EcocashWait--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  beforeMount() {},
  created() {
    Vue.filter("formatDate", function (value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY - hh:mm");
      }
    });
    this.fetchData();
  },
  methods: {
    printItem(item) {
      this.currentItem = item; // This will pass the item to Invoice component
      this.$nextTick(() => {

        let printableComponent = this.$refs.printableArea;
        html2canvas(printableComponent.$el).then(canvas => {
          let imgData = canvas.toDataURL('image/png');
          let doc = new jsPDF('p', 'mm');
          doc.addImage(imgData, 'PNG', 10, 10);
          doc.save('invoice.pdf');
        });
      })
    },


    fetchData() {
      axios
        .get('https://verniabackend.vernia.co.zw/api/quotationsnew')  // replace '<API_URL_here>' with your actual API endpoint
        .then((response) => {
          this.items = response.data;  // assuming your actual data is in 'data' key
        })
        .catch(error => console.error('Error:', error));
    },
    async fetchTransactions() {
      const JWTToken = this.$store.getters.accessToken;
      await axios
        .get(API + "/transactions/add_insurance_transaction/", {
          headers: { Authorization: `Bearer ${JWTToken}` },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log({ 1: res.data });
            this.arr1 = res.data;
            this.overlay = false;
            this.all_data = this.arr1;
            this.clear();
          } else if (res.status === 401) {
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        })
        .then(async () => {
          await axios
            .get(API + "/transactions/add_licensing_transaction/", {
              headers: { Authorization: `Bearer ${JWTToken}` },
            })
            .then((res) => {
              if (res.status === 200) {
                console.log({ 2: res });
                this.arr2 = res.data;
                this.overlay = false;
                this.clear();
              } else {
                this.overlay = false;
                window.clearTimeout(this.timeoutID);
                this.$store.commit("clearUserData");
                alert("Your Session has ended");
                // this.$router.push("/login");
                window.location.href = "/login";
              }
            });
        })
        .then(() => {
          this.all_data = null;
          this.all_data = this.arr1.concat(this.arr2);
        });
    },
    async fetchTransactionsByDate() {
      const startDate = `${this.dates[0]} 00:00:00.000000 +00:00`;
      const endDate = `${this.dates[1]} 23:59:59.999999 +00:00`;

      //  ? This method uses TRANSACTION API to filter by dates
      fetch(
        // ! FIX THIS TO MASK API ENDPOINT
        `http://196.43.100.211:5001/api/v1/transactions/filterbydates/${startDate}/${endDate}`,
        {
          method: "GET",
          headers: {},
        }
      )
        .then((response) => response.json())
        .then((response) => {
          this.qoutes = response;
          this.exportArr = response;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async renderTransactions() {
      // reset array len
      this.qoutes = [];

      this.all_data.forEach((item) => {
        if (
          moment(item.transaction_date).format("MM/DD/YYYY") >=
            moment(this.dates[0]).format("MM/DD/YYYY") &&
          moment(item.transaction_date).format("MM/DD/YYYY") <=
            moment(this.dates[1]).format("MM/DD/YYYY")
        ) {
          item.transaction_date = moment(String(item.transaction_date)).format(
            "MM/DD/YYYY - hh:mm"
          );
          this.qoutes.push(item);
        }
      });
    },
    async createExcel() {
      const filename = String(
        "transactions_report - " + moment(Date()).format("MM/DD/YYYY") + ".xlsx"
      );
      const data = this.qoutes;
      var ws = xlsx.utils.json_to_sheet(data);
      var wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "transactions");
      xlsx.writeFile(wb, filename);
    },


    clear() {
      this.delivery_town = "";
      this.client_contactnumber = null;
      this.delivery_town_object = null;
      this.overlay = false;
      this.viewTransactionDetails = false;
      this.trans_status = null;
      this.payment_message = "Sending Payment Request...";
      this.radio = null;
      this.RadioType = null;
      this.LicenceID = null;
      this.frequency = null;
      this.payment_method = null;
      this.delivery_method = null;
      this.client_idnumber = "";
      this.vehicle_type = null;
      this.vehicle_use = null;
      this.vehicle_make = "null";
      this.vehicle_model = "null";
      this.yearOfmanufacture = "null";
      this.tax_class = null;
      this.quote_message = null;
      this.quote_type = null;
      this.search = null;
      this.client_name = null;
      this.client_surname = null;
      this.client_email = null;
      this.client_phonenumber = "";
      this.paid_amount = null;
      this.cover_period_approved = null;
      this.approved_policy_message = null;
      this.approved_trans_status = null;
      this.approved_Vehiclevrn = null;
      this.IDNumber = null;
      this.LoadedBy = null;
      this.IssuerCompany = null;
      this.Message = null;
      this.add = false;
      this.show_quote = false;
      this.cover_period = null;
      this.trans_summary = false;
      this.LicenceID = null;
      this.quote_message = null;
      this.TotalTransAmount = "---";
      this.TransactionLicAmt = "---";
      this.PenaltiesAmt = "---";
      this.TotalLicAmt = "---";
      this.TotalRadioTVAmt = "---";
      this.RadioTVArrearsAmt = "---";
      this.TotalAmount = "---";
      this.vehicle_make = "null";
      this.vehicle_model = "null";
      this.yearOfmanufacture = "null";
      this.tax_class = null;
      this.vrn = null;
      this.vehicle_type = null;
      this.vehicle_use = null;
      this.first_name = null;
      this.last_name = null;
      this.email = null;
      this.user_id_number = null;
      this.phone_number = null;
      this.address = null;
      this.add = false;
      this.Address1 = null;
      this.Address2 = null;
      this.zone = null;
      this.delivery_town = null;
    },
  },
};
</script>
<style>
.my-input input {
  text-transform: uppercase;
}
</style>
